import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from '../../components/localizedLink'
import { GatsbyImage } from '@wardpeet/gatsby-image-nextgen/compat';
import { filterByLang } from '../../utility/utils'
import { FormattedMessage } from 'react-intl'
import pages from '../../utility/pages';
import Layout from '../../components/layout'
import SEO from '../../components/seo'


const Artwork = ({ pageContext: { locale } })  => {
  const { allContentfulContenutoGenerico } = useStaticQuery(
    graphql`
      query {
        allContentfulContenutoGenerico(filter: {contentful_id: {eq: "7tQUYnlmYRWAQNf2sZeICZ"}}) {
          edges {
            node {
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
              lancio
               {
                ... on ContentfulLavori {
                  titolo
                  copertina {
                    title
                    fluid(maxWidth: 450) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  internal  {
                    type
                  }
                }
                ... on ContentfulLavoriPerClienti {
                  titolo
                  copertina {
                    title
                    fluid(maxWidth: 450) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  internal  {
                    type
                  }
                }
              }
            }
          }
        } 
      }
    `
  ); 
  const lavori = filterByLang(allContentfulContenutoGenerico,locale)[0];
  const portfolio = lavori.lancio.filter(item => item.internal.type==='ContentfulLavori')[0];
  const cliente = lavori.lancio.filter(item => item.internal.type==='ContentfulLavoriPerClienti')[0];

  return (
    <Layout locale={locale}>
      <SEO title="lavori" />
      <div className="row">
        <div className="col-12 text-center">
          <FormattedMessage id="lavori">
              {txt => <h1 className="pagetitle">{txt}</h1>}
          </FormattedMessage>
        </div>
      </div>
      <div className="section section--top">
        <div className="row">
          <div className="col-12">
              <div className="section__text section__row">
                <div  className="section__text" dangerouslySetInnerHTML={{__html: lavori.testo.childMarkdownRemark.html}} />
              </div>
          </div>
        </div>
        <div className="row">
          {portfolio &&
          
              <div className="offset-1 col-4">
                <div className="card">
                  <Link to={`${pages[locale]('/i-miei-lavori/portfolio/')}`} className="card__copertinawrapper imghover">
                    <GatsbyImage alt={portfolio.titolo} title={portfolio.titolo} className="card__copertina" fluid={portfolio.copertina.fluid} />
                  </Link>
                  <div className="card__title text-center">
                    <Link to={`${pages[locale]('/i-miei-lavori/portfolio/')}`}>Portfolio</Link>
                  </div>
                </div>
              </div> 
          }
          {cliente &&
          
          <div className="offset-2 col-4">
            <div className="card">
              <Link to={`${pages[locale]('/i-miei-lavori/clienti/')}`} className="card__copertinawrapper imghover">
                <GatsbyImage alt={cliente.titolo} title={cliente.titolo} className="card__copertina" fluid={cliente.copertina.fluid} />
              </Link>
              <div className="card__title text-center">
                <Link to={`${pages[locale]('/i-miei-lavori/clienti/')}`}>Clienti</Link>
              </div>
            </div>
          </div> 
        }
        </div>
      
      </div>
    </Layout>
  )
}
export default Artwork