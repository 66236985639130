const slug = require('slug');
const locales = require('./locales');
const pages = require('./pages');

slug.defaults.modes['pretty'] = {
    replacement: '-',
    symbols: true,
    remove: /[.]/g,
    lower: true,
    charmap: slug.charmap,
    multicharmap: slug.multicharmap
};
slug.defaults.mode ='pretty';

const slugify = text => {
    if (text){
        return slug(text);
    }
}

const filterByLang = (items,locale) =>{
    return items.edges.map(edge => edge.node).filter(item => item.node_locale === locale);
}

const prefixPath = (lang, to) => {
    let locale = "";
    const isDefault = locales[lang].default;
    if (!isDefault) {
        locale = locales[lang].path;
    }
    const localizedTo = pages[lang](to) || to;
    return `${locale}${localizedTo}`
}
module.exports = {
    slugify,
    filterByLang,
    prefixPath
}